import request from '@/plugins/axios.config'

export function getDoctorList ({ pageNo, limit, nickName}) {
  return request({
    url: '/doctor/page/list',
    data: {
      pageNo, limit,
      entity: {
        nickName
      }
    }
  })
}

export function deleteDoctor ({ id}) {
  return request({
    url: `/doctor/delete/${id}`,
    type: 'delete'
  })

}
export function saveDoctorInfo (data) {
  return request({
    url: '/doctor/save/info',
    data: data
  })
}
