<template>
  <el-card
    shadow="never"
    v-loading="loading"
    class=""
    style="min-height: 70vh"
  >
    <div class="mb16 flex_center">
      <div class="flex_auto flex">
        <el-input
          class="w200px mr16"
          v-model="searchForm.nickName"
          placeholder="医生姓名"
          @blur="search"
        ></el-input>
        <el-button
            class="mr16 ml16"
            type="primary"
            round
            icon="el-icon-search"
            @click="search"
        >查询</el-button>
      </div>
      <div class="flex_none tr">

        <el-button type="primary" round icon="el-icon-edit-outline"  @click="showNewEdit()">新建</el-button>

      </div>
<!--      <div class="flex_none tr">-->
<!--        <el-button-->
<!--          round-->
<!--          type="success"-->
<!--          icon="el-icon-sold-out"-->
<!--          @click="onExport"-->
<!--          >Excel导出</el-button-->
<!--        >-->
<!--      </div>-->
    </div>
    <el-table :data="tableData" style="width: 100%"   height="480" @row-dblclick="handleDetail">
      <el-table-column prop="nickName" label="医生姓名" min-width="20%"></el-table-column>
      <el-table-column prop="deptName" label="所属部门" min-width="35%" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="remark" label="备注" min-width="35%" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column fixed="right" label="操作" min-width="10%">
        <template slot-scope="scope">
          <!-- <router-link :to="{ path: '/detail', query: { id: scope.row.id + '' }}"  custom> -->
          <el-button
            class="center mr8"
            type="text"
            size="small"
            @click="showEdit(scope.row)"
            >编辑</el-button
          >
          <!-- </router-link> -->
          <el-popconfirm
            title="确定删除吗？"
            @confirm="handleDelete(scope.row)"
          >
            <el-button slot="reference" type="text">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="tr mt16">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog :visible=editVisible :before-close="handleClose" @close="$emit('close')">
      <el-card>
        <el-form :model="editForm" ref="form" label-width="110px">

          <el-row  class="row-index">
            <el-col :span="0">
              <el-input v-model="form.id" v-show="false" size="small"></el-input>
            </el-col>
            <el-col :span="12">
              <el-form-item
                  label="医生姓名"
                  prop="nickName">
                <el-input v-model="form.nickName" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                  label="所属部门"
                  prop="deptName"
              >
                <el-select v-model="form.deptId">
                  <el-option
                      v-for="item in deptList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row  class="row-index">
            <el-col :span="12">
              <el-form-item
                  label="登录账号"
                  prop="userName">
                <el-input v-model="form.userName" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                  label="手机号码"
                  prop="phone">
                <el-input v-model="form.phone" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row  class="row-index">
            <el-col :span="24">
              <el-form-item
                  label="备注"
                  prop="remark"
              >
                <el-input v-model="form.remark"
                          type="textarea"
                          :rows="5"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="saveDoctorInfo">确 定</el-button>
        </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { getDoctorList, deleteDoctor,saveDoctorInfo } from "@/http/doctor/list";

export default {
  name: "DoctorList",
  components: {},
  props: {
    props: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      searchForm: {
        userId: "",
        userName: "",
        nickName: "",
        deptId: "",
        deptName: "",
        email: "",
        phone: "",
        sex: "",
        status:"",
        remark:""


      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pageNo: 1,
      limit: 20,
      total: 0,
      id:0,

      editVisible: false,
      form: {
        id: "",
        userId: "",
        userName: "",
        nickName: "",
        deptId: "",
        deptName: "",
        email: "",
        phone: "",
        sex: "",
        status:"",
        remark:""
      },
      editForm: {
        userId: "",
        userName: "",
        nickName: "",
        deptId: "",
        deptName: "",
        email: "",
        phone: "",
        sex: "",
        status:"",
        remark:""
      },
      deptList:[{
        value: '100',
        label: '王庄卫生院'
      }, {
        value: '101',
        label: '外科'
      }, {
        value: '102',
        label: '口腔科'
      }, {
        value: '103',
        label: '影像科'
      }, {
        value: '104',
        label: '化验室'
      },{
        value: '105',
        label: 'CT科'
      }],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {

    search() {
      this.pageNo = 1;
      this.total = 1;
      this.getList();
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getList();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    getParams() {
      const { nickName } = this.searchForm;
      const { pageNo, limit } = this;
      const data = { nickName, pageNo, limit };
      return data;
    },

    async getList() {
      if (this.loading) return;
      this.loading = true;
      getDoctorList(this.getParams())
        .then((res) => {
          if (res.success) {
            const result = res.result || {};
            this.tableData = result.records;
            this.total = result.total * 1;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async handleDelete(row) {
      this.$loading = true;
      deleteDoctor({ id: row.id })
        .then((res) => {
          if (res.success) {
            this.$message.success("删除成功");
            this.getList();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async handleEdit(row) {
      this.$router.replace({
        url: "/disease/list",
        query: { id: row.id },
      });
    },
    handleDetail(row) {
      this.$router.push({
        path: '/disease/detail',
        query: { id: row.id + '' },
      })
    },


    showEdit(row){
      this.editVisible = true;
      this.form = row;
      this.form.id  = row.id;
    },
    showNewEdit(){
      this.editVisible = true;
      this.form = {};
    },
    saveDoctorInfo() {
      saveDoctorInfo(this.form)
          .then((res) => {
            if (res.success) {
              this.getList();
            }
          })
          .finally(() => {
            this.editVisible = false;
            this.loading = false;
          });
    },
    handleClose() {
      this.editVisible = false;
      this.getList();
    }
  },
};
</script>

<style lang="less">
</style>
